import React from "react";
import TitleImageComponent from "../image/TitleImageComponent";
import browserHistory from "../../helper/browserHistory";
import EditSimpleValueComponent from "../moap-components/EditSimpleValueComponent";
import EditHTMLComponent from "../moap-components/EditHTMLComponent";
import {inject} from "mobx-react";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";


const PAGE_LINK = "Transparenz";

@inject("stores")
class Transparenz extends React.Component {


    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.stores.trackingStore.addVisit(PAGE_LINK);
    }

    render() {
       // const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";

        return (
            <Grid>
                <TitleImageComponent
                    imageLinkId={"transparenz-title-image-id"}
                    headerId={"transparenz-title-header-id"}
                    subheaderId={"transparenz-subtitle-header-id"}
                    separatorHref={"https://www.transparente-zivilgesellschaft.de"}
                    small
                />
                <Grid.Row>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/");
                                this.setState({});
                            }}>
                                HOME</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section className={"active-breadcrumb"}>TRANSPARENZ</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} textAlign={"center"}>
                        <Image centered
                               src={"https://www.buergerstiftung-kreis-rv.de/images/Transparente_ZivilgesellschaftPNG.png"}
                               size={"medium"}
                               alt={"ITZ logo"}
                               href={"https://www.transparency.de/mitmachen/initiative-transparente-zivilgesellschaft/"}
                        />
                        <h2>
                            <EditSimpleValueComponent id={"transparenz-title-sector-1-id"}/>
                        </h2>
                        <p>
                            <EditSimpleValueComponent id={"transparenz-title-sector-2-id"}/>
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row-bottom"}>
                    <Grid.Column computer={12} tablet={16} mobile={16}>
                        <p className={"text-block"}>
                            <EditHTMLComponent id={"transparenz-icon-card-1-subheader-id"}/>
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default Transparenz